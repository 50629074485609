<template>
  <div class="CEESAR-PortalLink" v-on:click="OnClick">
    <MaterialDesign-Icon v-if="icon != undefined" :icon="icon" size="18"/>
    <div class="CEESAR-PortalLinkLabel">
        {{fullLabel}}
    </div>
  </div>
</template>

<script>
export default {
    name:"CEESAR-PortalLink",
    props:{
        label: {type: String, required: true},
        url: {type: String, default: undefined},
        glyph: {type: String, default: undefined},
        emoji: {type: String, default: undefined},
        icon: {type: String, default: undefined}
    },
    computed:{
        fullLabel(){
            let label = "";
            if(this.emoji != undefined)
            {
                label = String.fromCodePoint(this.emoji) + " ";
            }
            label += this.label;
            return label;
        }
    },
    methods:
    {
        OnClick()
        {
            if(this.url != undefined)
            {
                window.location = this.url;
            }
        }
    }
}
</script>

<style>

</style>